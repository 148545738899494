// extracted by mini-css-extract-plugin
export var body = "legal-module--body--e89ff";
export var bodyLarge = "legal-module--bodyLarge--ceebb";
export var bodyLargeLight = "legal-module--bodyLargeLight--fa163";
export var bodySmall = "legal-module--bodySmall--69ad0";
export var bold = "legal-module--bold--26918";
export var center = "legal-module--center--12e1d";
export var downloadButton = "legal-module--downloadButton--f4596";
export var eyebrow = "legal-module--eyebrow--164f6";
export var filter = "legal-module--filter--a8ecc";
export var homepageHeadline = "legal-module--homepageHeadline--a3332";
export var internalHeadline = "legal-module--internalHeadline--a7d4f";
export var italic = "legal-module--italic--5e5ef";
export var legalWrap = "legal-module--legalWrap--9729d";
export var link = "legal-module--link--a1fcd";
export var linkSmall = "legal-module--linkSmall--de116";
export var quote = "legal-module--quote--93c83";