import * as React from "react";

import Frame from "../components/frame";
import { HeadMeta } from "../components/head-meta";
import Button from "../components/button";

import * as styles from "../styles/legal.module.scss";

export const Head = () => {
	return <HeadMeta title="Client Relationship Summary" />;
};
const AdvisoryRelationshipSummaryPage = () => {
	return (
		<Frame contained="true">
			<div className={styles.legalWrap}>
				<h1>PART 3 of FORM ADV: FORM CRS – CLIENT RELATIONSHIP SUMMARY</h1>
				<time className={styles.center}>May 31, 2023</time>
				<h2>Item 1. Introduction</h2>
				<p>
				Cynosure Management, LLC (“Cynosure”), is registered with the Securities
				and Exchange Commission as an investment advisor with offices throughout
				the United States. Registration of an investment advisor does not imply
				any level of skill or training.
				</p>
				<p>
				Brokerage and investment advisory services and fees differ, and it is
				important for retail investors to understand the differences. Free and simple
				tools are available to research firms and financial professionals
				at <a href="https://www.investor.gov/CRS" target="_blank" rel="noreferrer">
				Investor.gov/CRS</a>, which also provides
				educational materials about broker-dealers, investment advisers, and investing.
				</p>
{/* section 2 */}
				<h2>Item 2. Relationships and Services</h2>
				<h3>What investment services and advice can you provide me?</h3>
				<p>
					Cynosure offers the following services to high net worth and retail investors: Portfolio Advisory, Integrated Financial
					Planning, Consolidated Reporting, and Investment Consulting. We develop customized investment programs based on an
					analysis of various factors, such as your investment goals, tax position, diversification requirements, other assets held,
					social concerns, risk tolerance, etc. We continuously monitor investment accounts to ensure compliance with your stated
					goals and objectives.
				</p>
				<p>
					Cynosure typically receives discretionary authority in choosing third-party investment managers and determining which
					securities to buy or sell during an advisory partnership and we may allow you to impose reasonable investment restrictions
					with respect to your account, such as those related to specific securities, certain types of securities or industries. We
					exercise our investment discretion in line with your stated investment risk profile for your specific account. Additionally,
					we also provide non-discretionary services, where we seek prior authorization from the client before implementing our
					recommendations.
				</p>
				<p>
					Cynosure generally requires a minimum portfolio size of $10,000,000 However, we may waive our stated account
					minimums in our sole discretion.
				</p>
				<p>
					For a more detailed description of our services and account conditions, please refer to Items 4, 7, 8, 12, & 13 of our Form
					ADV Part 2A Disclosure Brochure available
					at: <a href="https://adviserinfo.sec.gov/firm/summary/281399" target="_blank" rel="noreferrer">https://adviserinfo.sec.gov/firm/summary/281399</a>
				</p>
				<div className="aside">
					<strong>Conversation Starters:</strong>
					<p>“Given my financial situation, should I choose an investment advisory service? Why or why not?”</p>
					<p>“How will you choose investments to recommend to me?”</p>
					<p>“What is your relevant experience, including your licenses, education and other qualifications? What
					do these qualifications mean?”</p>
				</div>
{/* section 3 */}
				<h2>Item 3: Fees, Costs, Conflicts and Standard of Conduct</h2>
				<h3>What fees will I pay?</h3>
				<p>
					Cynosure charges asset-based, fixed, and hourly fees. Our fee for wealth management services, including investment
					advisory, is up to 2.0% per year of assets under management, and the fee is negotiable based on certain criteria, including
					but not limited to, the size and scope of the services. We may, from time to time, quote a fixed annual retainer for services.
					If charged as a percentage of assets under advisement (AUA), the fee for Investment Consulting Services is not to exceed
					2.00% of the value of assets under advisement (AUA). Fees for Consolidated Reporting Services, when not inclusive of
					other advisory services provided such as Portfolio Advisory or Investment Consulting, are typically charged as a percentage
					of reportable assets, not to exceed 3 basis points. Hourly fees are generally charged at a rate of up to $400 per hour.
					In addition to the advisory fees described above, you will be responsible for paying certain fees and other expenses. Fees
					you pay us are separate and distinct from other fees and expenses charged to your accounts and investments, such as
					brokerage, custodial, trading, account maintenance, costs of the investment and related fees. You will pay fees and costs
					whether you make or lose money on your investments. Fees and costs will reduce any amount of money you make on
					your investments over time. Please make sure you understand what fees and costs you are paying and refer to Item 5 our
					Form ADV Part 2A Disclosure Brochure
					available at: <a href="https://adviserinfo.sec.gov/firm/summary/281399" target="_blank" rel="noreferrer">https://adviserinfo.sec.gov/firm/summary/281399</a>.
				</p>
				<div className="aside">
					<p><strong>Conversation Starters: </strong>
					“Help me understand how these fees and costs might affect my investments. If I give you $10,000 to
					invest, how much will go to fees and costs, and how much will be invested for me?”</p>
				</div>
				<h3>What are your legal obligations to me when acting as my investment adviser? How else does your firm make
				money and what conflicts of interest do you have?</h3>
				<p>
					<strong>When we act as your investment adviser</strong>, we have to act in your best interest and not put our interest ahead of yours. At
					the same time, the way we make money creates some conflicts with your interests. You should understand and ask us
					about these conflicts because they can affect the investment advice we provide you. Here are some examples to help you
					understand what this means.
				</p>
				<ul>
					<li>Cynosure manages proprietary private investment funds and this creates a conflict of interest if we recommend
					Cynosure funds over other unaffiliated funds invested in a similar fashion.</li>
					<li>The more assets there are in your advisory account, the more you will pay in fees, and we may therefore have an
					incentive to encourage you to increase the assets in your account.</li>
				</ul>
				<div className="aside">
					<p><strong>Conversation Starters: </strong>
					“How might your conflicts of interest affect me, and how will you address them?”</p>
				</div>
				<p>
				For more information, please refer to Items 4, 9, 10, 11, 12 & 14 of our Form ADV Part 2A Disclosure Brochure
				available at: <a href="https://adviserinfo.sec.gov/firm/summary/281399" target="_blank" rel="noreferrer">https://adviserinfo.sec.gov/firm/summary/281399</a>
				</p>
				<h3>How do your financial professionals make money?</h3>
				<p>
					Our financial professionals are compensated through a negotiated base salary. They are eligible for cash bonuses based
					on the overall performance of the business, as determined by senior management.
				</p>
{/* section 4 */}
				<h2>Item 4: Disciplinary History</h2>
				<h3>
					Do you or your financial professionals have legal or disciplinary
					history?
				</h3>
				<p>
					No. However, you can use the simple and free tools available
					at <a href="https://www.investor.gov/CRS" target="_blank" rel="noreferrer">Investor.gov/CRS</a> to research our
					firm and our financial professionals. This site also provides educational materials about investment advisers and investing.
				</p>
				<div className="aside">
					<p><strong>Conversation Starters: </strong>
					“As a financial professional, do you have any disciplinary history? For what type of conduct?”</p>
				</div>
{/* section 5 */}
				<h2>Item 5: Additional Information</h2>
				<p>
				Please visit cynosuregroup.com or call (801) 521-3100 for more information about our services or to obtain a copy of this
				form CRS.
				</p>
				<div className="aside">
					<p><strong>Conversation Starters:</strong>
					“Who is my primary contact person? Is he or she a representative of an investment adviser or a broker
					dealer? Who can I talk to if I have concerns about how this person is treating me?”</p>
				</div>
				<div className={styles.downloadButton}>
				<Button
						download="Form_CRS_20230601a_FINAL.pdf"
						to="/pdf/Form_CRS_20230601a_FINAL.pdf"
						type="secondary"
						variety="download">
						Download PDF
					</Button>
				</div>
			</div>
		</Frame>
	);
};

export default AdvisoryRelationshipSummaryPage;
